import React, { useState } from "react";

import cs from "./signup.module.scss";
import { images } from "../../helpers/images";
import Label from "../../components/form/Label";

import Error from "../../components/form/Error";
import useSignup from "../../hooks/useSignup";
import { Link } from "react-router-dom";
import PasswordRequirements from "./PasswordRequirements";

const SignUp = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [emailVerification, setEmailVerification] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [validations, setValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
  });

  const validatePassword = (password) => {
    setValidations({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /\d/.test(password),
      specialChar: /[@$!%*?&#]/.test(password),
    });
  };

  React.useEffect(() => {
    validatePassword(password);
  }, [password]);
  const {
    register,
    handleSubmit,
    errors,
    loading,
    watch,
    SignUpSubmitHandler,
  } = useSignup(setEmailVerification);
  console.log("SignUp");
  return (
    <div>
      <img
        src={images.XpireelogoBlue}
        alt="Xpiree Logo"
        className="w-[180px] h-[78px] absolute left-[30px] top-[15px] "
      />
      <section className={cs.FlexContainer}>
        <div className={cs.FlexCard1}></div>
        <div className={`${cs.FlexCard2} f-center`}>
          {!emailVerification && (
            <div className={cs.login_container}>
              <p className="h-text">Create account</p>
              <p className="p-text mt-[12px]">
                Get started by creating your account.
              </p>

              <button
                type="button"
                className="btn-filled text-primary2 mt-[32px] justify-center  bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-semibold rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 "
              >
                <img
                  src={images.google_icon}
                  alt="Google Logo"
                  className="w-6 h-6 me-2"
                />
                Sign up with Google
              </button>

              <form onSubmit={handleSubmit(SignUpSubmitHandler)}>
                {/* //NOTE - Name */}
                <div className="mt-[32px] ">
                  <Label id="user_name" label="Name" />
                  <input
                    type="text"
                    {...register("username")}
                    id="user_name"
                    className="x-input"
                    placeholder="Leslie Alexandar"
                  />
                  <Error errorName={errors.username} />
                </div>

                {/* //NOTE - Email */}
                <div className="mt-[20px]">
                  <Label id="user_email" label="Email" />
                  <input
                    type="email"
                    {...register("email")}
                    id="user_email"
                    className="x-input"
                    placeholder="example@xpiree.com"
                  />
                  <Error errorName={errors.email} />
                </div>

                {/* //NOTE - Password */}
                <div className="mt-[20px] relative">
                  <Label id="user_password" label="Password" />
                  <input
                    type={showPassword ? "text" : "password"}
                    {...register("password")}
                    id="user_password"
                    className="x-input"
                    onChangeCapture={(e) => setPassword(e.target.value)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder="Create a password"
                  />
                  <span
                    className="eye-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      "👁️"
                    ) : (
                      <img
                        src={images.hide_icon}
                        alt="hide"
                        className="w-4 h-4 opacity-50 mt-1"
                      />
                    )}
                  </span>
                  {/* <Error errorName={errors.password} /> */}
                </div>

                {isFocused && <PasswordRequirements password={password} />}

                {/* //NOTE - Confirm Password */}
                <div className="mt-[20px] relative">
                  <Label id="confirm_password" label="Confirm Password" />
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    {...register("confirmPassword")}
                    id="confirm_password"
                    className="x-input"
                    placeholder="Confirm password"
                  />
                  <span
                    className="eye-icon"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      "👁️"
                    ) : (
                      <img
                        src={images.hide_icon}
                        alt="hide"
                        className="w-4 h-4 opacity-50 mt-1"
                      />
                    )}
                  </span>
                  <Error errorName={errors.confirmPassword} />
                </div>

                <div className="flex items-start  mt-[20px]">
                  <div className="flex items-center h-5">
                    <input
                      id="isAgree"
                      {...register("isAgree")}
                      onChange={(e) => setIsAgreed(e.target.checked)}
                      type="checkbox"
                      value=""
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                    />
                  </div>

                  <label
                    htmlFor="isAgree"
                    className="ms-2 text-sm font-medium text-primary3 dark:text-gray-300"
                  >
                    I agree with the{" "}
                    <Link
                      to="https://xpiree.com/termsprivacypolicy/"
                      target="_blank"
                      className="font-bold hover:underline"
                    >
                      Terms & Privacy Policy
                    </Link>
                    .
                  </label>
                </div>

                <button
                  type="submit"
                  disabled={!isAgreed}
                  className={`btn-filled font-medium text-white mt-[32px] ${
                    isAgreed ? "bg-blue1" : "bg-slate-400 cursor-not-allowed"
                  }`}
                >
                  {loading ? (
                    <span className="loading loading-spinner"></span>
                  ) : (
                    "Create"
                  )}
                </button>
              </form>

              <div className="flex items-center justify-center  mt-[20px]">
                <label
                  htmlFor="remember"
                  className="ms-2 text-sm font-medium text-primary3 dark:text-gray-300"
                >
                  Already have an account?{" "}
                  <Link
                    to="/login"
                    className="font-bold text-blue-400 hover:underline"
                  >
                    Log in
                  </Link>
                </label>
              </div>
            </div>
          )}

          {emailVerification && (
            <div className={cs.login_container}>
              <p className="h-text">Verify your email</p>
              <p className="p-text mt-[12px] mb-3">
                An email has been sent to {watch("email")} with a link to verify
                your account. If you have not received the email after a few
                minutes, please check the spam folder.
              </p>

              <div className="flex mt-[20px]">
                <label
                  htmlFor="remember"
                  className="ms-2 text-sm font-medium text-primary3 dark:text-gray-300"
                >
                  Back to{" "}
                  <Link
                    to="/login"
                    className="font-semibold text-blue-400 hover:underline"
                  >
                    Login
                  </Link>
                </label>
              </div>

              {/* <label
                htmlFor="isAgree"
                className="  text-sm font-medium  dark:text-gray-300"
              >
                <span className="text-primary3"> Didn’t get the email?</span>{" "}
                <Link
                  to="https://xpiree.com/termsprivacypolicy/"
                  target="_blank"
                  className="font-bold text-blue2 hover:underline"
                >
                  Resend
                </Link>
                .
              </label> */}
            </div>
          )}

          {false && (
            <div className={cs.login_container}>
              <p className="h-text">Congratulations</p>
              <p className="p-text mt-[12px] mb-3">
                Your email address has been successfully verified.
              </p>

              <button
                className={`btn-filled font-medium text-white mt-[32px]  bg-blue1 `}
              >
                Proceed to Login
              </button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default SignUp;
