import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidation } from "../helpers/validations";
import { toast } from "react-toastify";
// import { USER } from "../services/interfaces/messages";
import AuthServices from "../services/api/auth.service";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../redux/features/authSlice";
import CryptoJS from "crypto-js";
import { useState } from "react";
import { updateUser } from "../redux/features/userSlice";

const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { isLoggedIn, user } = useSelector((state) => state.auth);

  const securityKey = process.env.REACT_APP_CRYPTO_KEY;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(loginValidation),
  });

  function encryptPassword(password) {
    return CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(password),
      CryptoJS.enc.Utf8.parse(securityKey),
      {
        keySize: 128 / 8,
        iv: CryptoJS.enc.Utf8.parse(securityKey),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
  }

  const ForgetPasswordHandler = () => {
    const userEmail = watch("email");
    if (userEmail) {
      AuthServices.forgetPassword(userEmail)
        .then((resp) => {
          if (resp.status == 200 && resp.data) {
            if (resp.data == "1") {
              toast.info("Please check your email");
            }
            if (resp.data == "-1") {
              toast.error("This email doesn't exist");
            }
          } else {
            // toast.error("Login Failed");
            toast.error(resp.title || "Something went wrong");
          }
        })
        .catch((err) => {
          console.error(err);
          // toast.error("Invalid Credential");
        });
    } else {
      toast.info("Enter Email first!");
    }
    // toast.success("ForgetLogin Successful");
  };

  const LoginSubmitHandler = (data) => {
    const payload = {
      userName: data.email,
      rememberMe: data.rememberMe,
      password: encryptPassword(data.password),
      loginSource: 1,
    };

    setLoading(true);
    AuthServices.loginUser(payload)
      .then((resp) => {
        if (resp.status == 200 && resp.data !== -1) {
          toast.success("Login Successful");
          reset();
          dispatch(login(resp.data));
          getUserData();
          navigate("/dashboard");
        } else {
          // toast.error("Login Failed");
          toast.error(resp.title || "Login Failed");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        // toast.error("Invalid Credential");
        setLoading(false);
      });
  };

  const getUserData = () => {
    AuthServices.getUserInfo()
      .then((resp) => {
        if (resp.status == 200 && resp.data) {
          localStorage.setItem("userData", JSON.stringify(resp.data));
          dispatch(updateUser(resp.data));
          // setUserInfo(resp.data);
          // UserPic = BaseURL.replace(/\/api$/, "") + "/" + resp.data.ProfilePic;
          // if (resp.data.ProfilePic) {
          //   setProfilePic(UserPic);
          // }
          // console.log(UserPic);
        }
      })
      .catch((err) => {});
  };

  return {
    register,
    handleSubmit,
    errors,
    LoginSubmitHandler,
    ForgetPasswordHandler,
    loading,
  };
};

export default useLogin;
