import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ResetPasswordValidation,
  signUpValidation,
} from "../helpers/validations";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import AuthServices from "../services/api/auth.service";

const useResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { email, token } = useParams();
  // Decode token if necessary
  const decodedToken = decodeURIComponent(token);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(ResetPasswordValidation),
    mode: "onChange",
    reValidateMode: "onBlur",
  });

  const ResetPasswordSubmitHandler = (data) => {
    console.log("✅✅✅✅");
    const payload = {
      // userName: data.email,
      // rememberMe: data.rememberMe,
      // password: encryptPassword(data.password),
      // loginSource: 1,
      Password: data.password,
      ConfirmPassword: data.password,
      email: email || "",
      token: decodedToken || "",
    };

    // return;

    setLoading(true);

    AuthServices.resetPassword(payload)
      .then((resp) => {
        console.log({ resp });
        if (resp.status == 200 && resp?.data?.Succeeded) {
          if (resp?.data?.Succeeded == true) {
            toast.success("Password has been Updated.", "Successfully!!");
            // setEmailVerification(true);
          }

          // reset();
          // navigate("/login");
        } else {
          toast.error("Process Failed");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        if (err?.response?.data) {
          toast.error(err?.response?.data || "Process Failed");
        }
        setLoading(false);
      });
  };

  const SignUpSubmitHandler = (data) => {
    const payload = {
      name: data.username,
      email: data.email,
      password: data.password,
    };

    setLoading(true);

    AuthServices.resetPassword(payload)
      .then((resp) => {
        if (resp.status == 200 && resp.data) {
          if (resp.data == "1") {
            toast.success("Verification email sent");
            // setEmailVerification(true);
          }
          if (resp.data == "-1") {
            toast.error("Email sending problem");
          }
          if (resp.data == "-3") {
            toast.info("This Email is already registered");
          }

          // reset();
          // navigate("/login");
        } else {
          toast.error("Sign Up Process Failed");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  return {
    register,
    handleSubmit,
    errors,

    ResetPasswordSubmitHandler,
    loading,
    watch,
  };
};

export default useResetPassword;
