import React, { useState } from "react";

import cs from "./resetPassword.module.scss";
import { images } from "../../helpers/images";
import Label from "../../components/form/Label";

import Error from "../../components/form/Error";
import useLogin from "../../hooks/useLogin";
import { Link, useParams } from "react-router-dom";
import useResetPassword from "../../hooks/useResetPassword";
import PasswordRequirements from "./PasswordRequirements";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    errors,
    ResetPasswordSubmitHandler,
    loading,
  } = useResetPassword();

  const [isAgreed, setIsAgreed] = useState(false);
  const [emailVerification, setEmailVerification] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [validations, setValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
  });

  const validatePassword = (password) => {
    setValidations({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /\d/.test(password),
      specialChar: /[@$!%*?&#]/.test(password),
    });
  };

  React.useEffect(() => {
    validatePassword(password);
  }, [password]);

  return (
    <div>
      <img
        src={images.XpireelogoBlue}
        alt="Xpiree Logo"
        className="w-[180px] h-[78px] absolute left-[30px] top-[15px] "
      />
      <section className={cs.FlexContainer}>
        <div className={cs.FlexCard1}></div>
        <div className={`${cs.FlexCard2} f-center`}>
          <div className={cs.login_container}>
            <p className="h-text w-max">Create New Password</p>
            <p className="p-text mt-[12px]">
              Your new password must be different from prevoiusly used
              passwords.
            </p>

            {/* <button className="btn-filled mt-[32px]">Sign up with Google</button> */}

            <form onSubmit={handleSubmit(ResetPasswordSubmitHandler)}>
              {/* //NOTE - Password */}
              <div className="mt-[20px] relative">
                <Label id="user_password" label="Password" />
                <input
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  id="user_password"
                  className="x-input"
                  onChangeCapture={(e) => setPassword(e.target.value)}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  placeholder="Create a password"
                />
                <span
                  className="eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    "👁️"
                  ) : (
                    <img
                      src={images.hide_icon}
                      alt="hide"
                      className="w-4 h-4 opacity-50 mt-1"
                    />
                  )}
                </span>
                {/* <Error errorName={errors.password} /> */}
              </div>

              {isFocused && <PasswordRequirements password={password} />}

              {/* //NOTE - Confirm Password */}
              <div className="mt-[20px] relative">
                <Label id="confirm_password" label="Confirm Password" />
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  {...register("confirmPassword")}
                  id="confirm_password"
                  className="x-input"
                  placeholder="Confirm password"
                />
                <span
                  className="eye-icon"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    "👁️"
                  ) : (
                    <img
                      src={images.hide_icon}
                      alt="hide"
                      className="w-4 h-4 opacity-50 mt-1"
                    />
                  )}
                </span>
                <Error errorName={errors.confirmPassword} />
              </div>

              <button
                type="submit"
                className="btn-filled bg-blue1 text-white mt-[32px]"
              >
                {loading ? (
                  <span className="loading loading-spinner"></span>
                ) : (
                  "Reset Password"
                )}
              </button>
            </form>

            <div className="flex items-center justify-center  mt-[20px]">
              <label
                htmlFor="remember"
                className="ms-2 text-sm font-medium text-primary3 dark:text-gray-300"
              >
                Back to{" "}
                <Link
                  to="/login"
                  className="font-semibold text-blue-400 hover:underline"
                >
                  Login
                </Link>
              </label>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResetPassword;
